function Balloon2(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
      id={props.id} className={`small-balloon ${props.className}`} style={props.style}
      viewBox="0 0 512 512">
      <g>
        
          <rect x="172.02" y="375.498" transform="matrix(-0.2843 -0.9587 0.9587 -0.2843 -96.3085 695.1006)" style={{fill: '#CACACA'}} width="78.541" height="15.999"/>
        
          <rect x="261.483" y="375.517" transform="matrix(0.2843 -0.9587 0.9587 0.2843 -152.4441 562.8214)" style={{fill: '#CACACA'}} width="78.541" height="15.999"/>
      </g>
      <path style={{fill: '#E95454'}} d="M325.376,353.776C377.664,286,433.424,238.064,433.424,177.424C433.424,79.536,354.064,0,256,0
        S78.576,79.536,78.576,177.424c0,60.64,55.76,108.576,108.048,176.352H325.376z"/>
      <path style={{fill: '#DAEFF6'}} d="M301.456,353.776c34.272-67.776,70.8-115.712,70.8-176.352C372.272,79.536,320.272,0,256,0
        c-64.256,0-116.256,79.536-116.256,177.424c0,60.64,36.544,108.576,70.8,176.352H301.456z"/>
      <path style={{fill: '#E95454'}} d="M277.76,353.776c16.4-67.776,33.888-115.712,33.888-176.352C311.648,79.536,286.752,0,256,0
        s-55.648,79.536-55.648,177.424c0,60.64,17.488,108.576,33.888,176.352H277.76z"/>
      <g>
        <path style={{fill: '#EF7071'}} d="M397.152,180.528c-4.512-0.432-8.256,2.976-8.576,7.392c-1.008,13.616-3.968,27.344-9.04,42
          c-1.44,4.192,0.768,8.736,4.944,10.192c4.224,1.44,8.752-0.816,10.176-4.944c5.536-16,8.768-31.056,9.872-46.064
          C404.864,184.704,401.568,180.864,397.152,180.528z"/>
        <path style={{fill: '#EF7071'}} d="M396.304,156.672c4.384-0.464,7.568-4.4,7.104-8.8c-3.168-29.712-11.248-58.144-23.376-82.192
          c-1.984-3.936-6.8-5.52-10.736-3.536c-3.952,1.984-5.536,6.8-3.536,10.752c11.264,22.336,18.784,48.848,21.76,76.656
          C387.968,153.968,391.92,157.152,396.304,156.672z"/>
      </g>
      <g style={{opacity: '0.1'}}>
        <path style={{fill: '#010101'}} d="M352.656,319.856c-8.992,10.848-18.144,22.048-27.296,33.904H186.656
          c-9.152-11.872-18.304-23.056-27.296-33.904H352.656z"/>
      </g>
      <path style={{fill: '#FFCC5B'}} d="M298.848,413.568h-85.696c-8.832,0-16,7.168-16,16V496c0,8.832,7.168,16,16,16h85.696
        c8.832,0,16-7.168,16-16v-66.432C314.848,420.736,307.68,413.568,298.848,413.568z"/>
      <path style={{fill: '#FFDB70'}} d="M298.848,413.568h-33.36c-3.84,0-7.328,2.784-7.664,6.512c-0.464,4.416,3.024,8.128,7.328,8.128h9.92
        c3.6,0,6.512,2.896,6.384,6.512v0.576c0.24,3.376-2.448,6.16-5.92,6.16h-16.848c-3.84,0-7.328,2.784-7.664,6.624
        c-0.464,4.304,3.024,8.016,7.312,8.016h16.736c3.6,0,6.512,2.912,6.384,6.512v0.576c0.24,3.488-2.448,6.272-5.92,6.272h-25.328
        c-3.84,0-7.328,2.672-7.664,6.512c-0.464,4.416,3.024,8.016,7.328,8.016h25.216c3.6,0,6.512,3.024,6.384,6.512v0.704
        c0.24,3.376-2.448,6.16-5.92,6.16h-10.64c-3.84,0-7.328,2.784-7.664,6.512c-0.48,4.416,2.992,8.128,7.296,8.128h34.304
        c8.832,0,16-7.168,16-16v-66.432C314.848,420.736,307.68,413.568,298.848,413.568z"/>
      <rect x="238.24" y="413.568" style={{fill: '#EC9922'}} width="35.504" height="29.152"/>
    </svg>

  )
}

export default Balloon2;