function PlaneMsg4(props) {
  return (
    <svg width="1427px" height="239px" viewBox="0 0 1427 239" version="1.1" xmlns="http://www.w3.org/2000/svg" className="plane-msg" id="planeMsg4">
        <title>Page 1</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M1299.63562,173.842916 C1301.13741,187.683733 1306.87938,194.604141 1316.86151,194.604141 C1326.84364,194.604141 1332.8898,187.3504 1335,172.842916 L1299.63562,173.842916 Z" id="Path-5" fill="#373737"></path>
          <path d="M850.031703,41.3000894 L934.56888,95.4034475 L1027.44058,93.5731621 L1027.44058,95.4034475 L934.56888,98.5296708 L846.898214,188.525737 L846.898214,184.960948 L932.258675,97.1217316 C895.220627,73.1915791 867.099724,55.1365181 847.895966,42.9565487 C828.692209,30.7765793 825.989989,29.2820969 839.789308,38.4731017 L850.031703,41.3000894 Z" id="Path-11" fill="#EEEEEE"></path>
          <polygon id="Path-9" fill="#D8D8D8" points="1216.23406 66.289578 1221.03753 66.289578 1222.06581 56.5085321 1216.23406 57.5078213 1213.51358 64.9808573"></polygon>
          <path d="M1206.55517,66.289578 C1205.78025,56.7251717 1207.24755,51.9429685 1210.95705,51.9429685 C1214.66656,51.9429685 1215.87022,51.9429685 1214.56803,51.9429685 L1222.05499,51.9429685 L1223.45138,57.5078213 C1218.63984,58.5353368 1216.23406,59.9960837 1216.23406,61.8900623 C1216.23406,63.7840408 1216.23406,65.2505461 1216.23406,66.289578 L1214.56803,66.289578 L1214.56803,68.5 C1219.68165,69.6233474 1222.64277,70.9500872 1223.45138,72.4802192 C1224.26,74.0103513 1221.29888,75.8565269 1214.56803,78.0187461 L1201.30647,78.0187461 L1206.55517,72.4802192 C1207.08244,69.8267397 1208.54974,68.5 1210.95705,68.5 C1212.31937,68.5 1211.13662,68.6460558 1210.95705,68.1741329 C1210.86523,67.9327975 1210.86523,67.3046125 1210.95705,66.289578 L1206.55517,66.289578 Z" id="Path-8" fill="#282828"></path>
          <path d="M1408.78255,79.7783111 C1420.94506,82.7541672 1427.02632,86.1173228 1427.02632,89.867778 C1427.02632,93.6182332 1420.07148,97.4201197 1406.16179,101.273437 L1406.16179,89.867778 L1408.78255,79.7783111 Z" id="Path-6" fill="#D8D8D8"></path>
          <path d="M1007.31291,63.6747744 C1013.59591,59.0748915 1074.7153,57.4551302 1190.67108,58.8154906 C1204.85804,77.4110673 1218.9398,78.220948 1232.91637,61.2451325 C1250.08464,58.1645976 1307.32378,58.9744782 1404.63378,63.6747744 C1411.73204,88.546179 1408.09564,108.282163 1393.7246,122.882727 C1379.35355,137.483291 1358.207,148.855716 1330.28494,157 C1338.45667,164.60386 1342.54253,170.429594 1342.54253,174.477205 C1342.54253,180.54862 1294.40503,177.787532 1290.45871,174.477205 C1287.82784,172.27032 1290.46422,166.444585 1298.36788,157 C1247.71586,149.612303 1212.45654,143.838686 1192.58991,139.679147 C1150.33237,130.831557 1118.12053,122.927668 1098.39772,118.209142 C1087.18847,115.52742 1076.54194,114.073432 1065.73798,112.121989 C1058.6751,110.846268 1048.34485,108.948705 1034.74724,106.4293 C1029.27906,101.535851 1025.73927,98.2581933 1024.12788,96.5963255 C1005.51957,77.4051003 1001.75221,67.7458536 1007.31291,63.6747744 Z" id="Path" fill="#AE1914"></path>
          <path d="M1013.73116,61.2451325 C1007.99287,62.1694588 1005.21376,63.6418977 1005.39384,65.6624492 C1006.55977,78.7449269 1021.13563,94.0605997 1024.12788,96.5963255 C1027.81334,99.7195046 1155.82207,99.0587318 1408.15406,94.614007 C1409.64956,77.5378619 1408.47614,67.2247844 1404.63378,63.6747744 C1400.79142,60.1247645 1343.55228,59.3148838 1232.91637,61.2451325 C1227.39123,69.4993769 1221.37729,73.6264992 1214.87456,73.6264992 C1208.36854,73.6264992 1198.92388,65.6686593 1190.67108,58.8154906 C1187.84647,56.4699152 1128.8665,57.2797959 1013.73116,61.2451325 Z" id="Path-2" fill="#BD3334"></path>
          <rect id="Rectangle" fill="#32100C" x="1319" y="60" width="3" height="17"></rect>
          <rect id="Rectangle" fill="#32100C" x="1326" y="60" width="3" height="17"></rect>
          <rect id="Rectangle" fill="#32100C" x="1333" y="60" width="3" height="17"></rect>
          <rect id="Rectangle" fill="#32100C" x="1340" y="60" width="3" height="17"></rect>
          <rect id="Rectangle" fill="#32100C" x="1319" y="60" width="3" height="17"></rect>
          <rect id="Rectangle" fill="#32100C" x="1347" y="60" width="3" height="17"></rect>
          <rect id="Rectangle" fill="#E7E7E7" x="1235" y="42" width="4" height="79"></rect>
          <rect id="Rectangle" fill="#E7E7E7" x="1321" y="43" width="4" height="79"></rect>
          <polygon id="Path-3" fill="#E7E7E7" points="1237 46.3220681 1323 122 1323 115.786438 1239 42"></polygon>
          <polygon id="Path-3" fill="#E7E7E7" transform="translate(1280.000000, 82.000000) scale(-1, 1) translate(-1280.000000, -82.000000) " points="1237 46.3220681 1323 122 1323 115.786438 1239 42"></polygon>
          <path d="M1180,137.281735 L1345.85479,139.013907 C1353.52146,135.056832 1354.02146,131.098416 1347.35479,127.138658 C1340.68812,123.1789 1329.10543,120.466014 1312.60672,119 C1269.62122,120.899825 1245.49952,122.731224 1240.24163,124.494198 C1234.98374,126.257171 1214.90319,130.519683 1180,137.281735 Z" id="Path-4" fill="#831B1B" transform="translate(1266.002977, 129.006953) scale(1, -1) translate(-1266.002977, -129.006953) "></path>
          <path d="M1182,43.2817346 L1347.85479,45.0139067 C1355.52146,41.056832 1356.02146,37.0984157 1349.35479,33.1386578 C1342.68812,29.1788998 1331.10543,26.4660139 1314.60672,25 C1271.62122,26.8998252 1247.49952,28.7312244 1242.24163,30.4941977 C1236.98374,32.2571711 1216.90319,36.5196834 1182,43.2817346 Z" id="Path-4" fill="#831B1B"></path>
          <rect id="Rectangle" fill="#D8D8D8" x="1416" y="14" width="2" height="149"></rect>
          <path d="M1005.39384,68.3646017 C1003.36335,53.3409861 1004.76186,43.7233448 1009.58938,39.511678 C1015.24334,34.5790103 1031.74724,19.9228839 1040.8077,25 C1050.18929,31.5893457 1066.09407,46.0442129 1088.52202,68.3646017 L1005.39384,68.3646017 Z" id="Path-7" fill="#AF1A17"></path>
          <path d="M-0.0152735636,18.5147486 L129.497302,139.013907 L-0.0152735636,219.827267 C65.6717194,232.578127 139.743026,238.953557 222.198645,238.953557 C253.334224,238.953557 342.835664,227.892962 425.008215,219.827267 C531.140561,209.409785 645.087136,199.806768 685.385028,199.806768 C733.050612,199.806768 787.932837,206.480267 850.031703,219.827267 L850.031703,18.5147486 C834.274684,13.3208299 819.032525,9.76810453 804.305226,7.85657237 C767.836645,3.1231267 709.485565,0.186864677 699.525061,0.593760666 C671.235806,1.74940342 609.678392,1.74940342 524.197761,7.85657237 C444.81088,13.5283729 361.203743,26.4031007 320.363924,30.4408906 C280.414737,34.3906247 195.504666,42.8168153 105.387724,37.2164887 C80.4329517,35.6656718 45.2986193,29.4317585 -0.0152735636,18.5147486 Z" id="Path-10" fill="#AE1914"></path>
          <text id="And-on-our-big-day-w" fill="#E8E8E8" transform="translate(485.643252, 116.681409) rotate(-3.000000) translate(-485.643252, -116.681409) " font-family="PingFangTC-Medium, PingFang TC" font-size="33" font-weight="400">
              <tspan x="175.643252" y="62.1814093">And on our big day we and our family </tspan>
              <tspan x="175.643252" y="108.181409">would love everyone of you to </tspan>
              <tspan x="175.643252" y="154.181409">accompany us with your prayers and </tspan>
              <tspan x="175.643252" y="200.181409">blessings</tspan>
          </text>
        </g>
    </svg>
  )
}

export default PlaneMsg4;