function Balloon1(props) {
  return (
    <svg width="1427px" height="239px" viewBox="0 0 1427 239" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`small-balloon ${props.className}`} style={props.style}>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="hot-air-balloon" fill-rule="nonzero">
                <path d="M134.1,455.206 C129.833,455.206 126.374,451.747 126.374,447.48 L126.374,395.447 C126.374,391.18 129.833,387.721 134.1,387.721 C138.367,387.721 141.826,391.18 141.826,395.447 L141.826,447.48 C141.827,451.747 138.367,455.206 134.1,455.206 Z" id="Path" fill="#AFAAB4"></path>
                <path d="M201.9,455.206 C197.632,455.206 194.174,451.747 194.174,447.48 L194.174,395.447 C194.174,391.18 197.633,387.721 201.9,387.721 C206.167,387.721 209.626,391.18 209.626,395.447 L209.626,447.48 C209.626,451.747 206.167,455.206 201.9,455.206 Z" id="Path" fill="#AFAAB4"></path>
                <path d="M168,395.447 L207.187,395.447 C208.92,395.447 210.571,394.72 211.743,393.443 C227.312,376.479 335.545,255.358 335.545,166.504 C335.545,74.546 260.532,0 168,0 C75.468,0 0.455,74.546 0.455,166.504 C0.455,255.358 108.687,376.479 124.257,393.443 C125.429,394.72 127.08,395.447 128.813,395.447 L168,395.447 Z" id="Path" fill="#E28086"></path>
                <path d="M312.666,82.48 C313.342,89.237 313.691,96.09 313.691,103.024 C313.691,215.672 222.372,306.991 109.724,306.991 C89.279,306.991 69.542,303.969 50.92,298.371 C81.424,345.861 115.975,384.416 124.259,393.444 C125.431,394.721 127.08,395.446 128.813,395.446 L168,395.446 L207.187,395.446 C208.92,395.446 210.571,394.719 211.743,393.442 C227.312,376.478 335.545,255.357 335.545,166.503 C335.545,135.855 327.203,107.146 312.666,82.48 L312.666,82.48 Z" id="Path" fill="#DD636E"></path>
                <path d="M168,0 C104.779,0 53.528,74.546 53.528,166.504 C53.528,262.244 139.382,395.447 139.382,395.447 C139.382,395.447 147.251,395.447 154.992,395.447 C154.99,395.442 115.968,262.242 115.968,166.504 C115.967,74.547 139.263,0 168,0 C196.737,0 220.033,74.547 220.033,166.504 C220.033,262.242 181.01,395.441 181.009,395.447 L196.619,395.447 C196.619,395.447 282.473,262.244 282.473,166.504 C282.473,74.546 231.221,0 168,0 Z" id="Path" fill="#F9F6F9"></path>
                <g id="Group" transform="translate(91.000000, 223.000000)" fill="#DFDDE2">
                    <path d="M18.724,83.992 C12.578,83.992 6.499,83.704 0.491,83.172 C23.475,133.794 48.382,172.447 48.382,172.447 C48.382,172.447 56.251,172.447 63.992,172.447 C63.991,172.444 52.592,133.525 42.115,82.649 C34.438,83.526 26.636,83.992 18.724,83.992 L18.724,83.992 Z" id="Path"></path>
                    <path d="M116.489,59.071 C105.079,120.996 90.009,172.444 90.008,172.447 L105.618,172.447 C105.618,172.447 162.303,84.499 183.553,0.165 C165.86,24.398 142.96,44.586 116.489,59.071 L116.489,59.071 Z" id="Path"></path>
                </g>
                <path d="M199.22,512 L136.78,512 C131.033,512 126.374,507.341 126.374,501.594 L126.374,447.48 L209.626,447.48 L209.626,501.594 C209.626,507.341 204.967,512 199.22,512 Z" id="Path" fill="#B78B7A"></path>
                <path d="M186.732,447.48 L186.732,501.594 C186.732,507.341 182.073,512 176.326,512 L199.22,512 C204.967,512 209.626,507.341 209.626,501.594 L209.626,447.48 L186.732,447.48 Z" id="Path" fill="#AA7A63"></path>
                <path d="M220.032,455.206 L115.967,455.206 C111.7,455.206 108.241,451.747 108.241,447.48 C108.241,443.213 111.7,439.754 115.967,439.754 L220.032,439.754 C224.3,439.754 227.758,443.213 227.758,447.48 C227.758,451.747 224.3,455.206 220.032,455.206 Z" id="Path" fill="#AA7A63"></path>
            </g>
        </g>
    </svg>
  )
}

export default Balloon1;